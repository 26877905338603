<template>
  <template v-if="restockNoticeModalReactive">
    <MoleculesConsumerModal v-model="restockNoticeModalReactive.entryModalOpen">
      <div :class="$style.restock_notice_modal">
        <h3>再入荷お知らせメールの申し込み</h3>
        <p>商品が再入荷した際にメールでお知らせします。</p>
        <div v-if="!userId">
          <MoleculesConsumerFormItem label="required" :error="!!state.errorMessage" :error-message="state.errorMessage">
            <template #title>メールアドレス</template>
            <AtomsConsumerInput
              id="email"
              v-model="state.form.email"
              type="email"
              placeholder="例）tential@e-mail.com"
            />
          </MoleculesConsumerFormItem>
        </div>
        <AtomsConsumerButton
          variant="primary"
          block
          :disabled="!state.form.email && !userId"
          @click="sendRestockReception"
        >
          登録する
        </AtomsConsumerButton>
      </div>
    </MoleculesConsumerModal>

    <MoleculesConsumerModal v-model="restockNoticeModalReactive.doneModalOpen">
      <div :class="$style.restock_notice_done_modal">
        <h3>再入荷メールを受け付けました</h3>
        <div>
          メールが届かない場合、<br />
          <code>@tential.jp</code>のドメイン受信拒否がされているか、<br />
          メールアドレスに誤りがある場合がございます。
        </div>
        <AtomsConsumerButton variant="secondary" block @click="changeRestockNoticeDoneModalState">
          閉じる
        </AtomsConsumerButton>
      </div>
    </MoleculesConsumerModal>
  </template>
</template>

<script setup lang="ts">
import { PagePartsProductType } from "~/types/product"
import { SkuFragmentFragment, RestockReceptionCreateInput } from "~/types/type.generated"
import { useToast, useRestockNoticeModal } from "~/state"
import { useStore } from "~/stores"
import { EventHandler } from "~/types/events"
import { emailValidation } from "~/utils/functions/validate"
import { getSiteProductPageUrl } from "~/utils/functions/product"
import {
  usePublicSearchSiteProductQuery,
  useRestockReceptionCreateMutation,
  useOwnRestockReceptionCreateMutation,
} from "~/gql/urql.generated"

interface State {
  form: RestockReceptionCreateInput
  errorMessage: string
}

type Props = {
  sku: SkuFragmentFragment
  product: PagePartsProductType
}
const props = withDefaults(defineProps<Props>(), {
  product: undefined,
  sku: undefined,
})

const emit = defineEmits<{
  (e: "close"): void
}>()

const toast = useToast()
const store = useStore()
const { getSiteId } = useSite()
const userId = store.user.document_id
const restockNoticeModal = useRestockNoticeModal()
const restockNoticeModalReactive = restockNoticeModal?.restockNoticeModalReactive
const state: State = reactive({
  form: {
    email: "",
    product_id: "",
    sku_id: "",
    sku_code: "",
    name: "",
    size: "",
    color: "",
    page_slug: "",
    send_date: 0,
    site_id: getSiteId(),
    local_user_id: "",
    status: "pre",
  },
  errorMessage: "",
})

const { executeQuery } = usePublicSearchSiteProductQuery({
  pause: true,
  variables: {
    keywords: [props.product?.slug],
    filter: {},
    categoryId: "",
  },
})

const { executeMutation } = useRestockReceptionCreateMutation()
const { executeMutation: ownRestockReceptionMutation } = useOwnRestockReceptionCreateMutation()
const changeRestockNoticeModalState: EventHandler = () => {
  restockNoticeModal?.entryModalHandler()
  state.errorMessage = ""
}

const changeRestockNoticeDoneModalState: EventHandler = () => {
  restockNoticeModal?.doneModalHandler()
  state.errorMessage = ""
  emit("close")
}

const sendRestockReception = async (): Promise<void> => {
  if (state.form.email && !userId) {
    const { errorMessage, isPassed } = emailValidation(state.form.email)
    state.errorMessage = errorMessage
    if (!isPassed) return
  }
  changeRestockNoticeModalState()
  if (props.product && props.product.slug) {
    const { data } = await executeQuery()
    const searchProduct = data.value?.publicSearchSiteProduct
    if (searchProduct && searchProduct.length > 0) {
      state.form.page_slug = getSiteProductPageUrl(searchProduct[0])
    }
  }
  if (props.product && props.product.document_id) state.form.product_id = props.product.document_id
  state.form.sku_id = props.sku.document_id
  state.form.sku_code = props.sku.sku_code
  state.form.name = props.sku.name
  state.form.size = props.sku.size
  state.form.color = props.sku.color

  const { error } = userId
    ? await ownRestockReceptionMutation({ record: state.form })
    : await executeMutation({ record: state.form })

  if (error) {
    toast?.showErrorToasted("エラーが発生しました")
    return
  }
  changeRestockNoticeDoneModalState()
  state.form.email = ""
  state.errorMessage = ""
}

onMounted(() => {
  state.form.local_user_id = localStorage.getItem("localUserId") ? (localStorage.getItem("localUserId") as string) : ""
})
</script>

<style module scoped lang="scss">
.restock_notice_modal,
.restock_notice_done_modal {
  max-width: 400px;
  width: calc(100vw - 2rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h3 {
    color: $primary;
  }
}
</style>

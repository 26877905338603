<template>
  <MoleculesConsumerModal v-model="localValue">
    <div :class="$style.body">
      <h2 :class="$style.head_text">予約商品発送についての<br :class="$style.br" />重要なお知らせ</h2>
      <div :class="$style.attention">
        <div :class="$style.section1">
          <AtomsConsumerImage
            v-if="minScreenMd"
            src="/images/site/components/order/pre-order-attention/head01_pc.svg"
          />
          <AtomsConsumerImage v-else src="/images/site/components/order/pre-order-attention/head01_sp.svg" />

          <p :class="[$style.main_text, $style.red]"> <strong>入荷の遅い商品</strong>に合わせて発送いたします。 </p>

          <AtomsConsumerImage
            v-if="minScreenMd"
            :class="$style.shipping_flow_image"
            src="/images/site/components/order/pre-order-attention/shipping_flow_image_pc.svg"
          />
          <AtomsConsumerImage
            v-else
            :class="$style.shipping_flow_image"
            src="/images/site/components/order/pre-order-attention/shipping_flow_image_sp.svg"
          />
          <div :class="$style.sub_text">
            <p> ※予約商品の入荷日は変更になる場合がございます。あらかじめご了承ください。 </p>
            <p>
              ※予約商品と通常商品を同時に購入した場合、後で別々に発送することはできません。予約商品の入荷予定日を改めてご確認ください。
            </p>
          </div>
        </div>

        <div :class="$style.section2">
          <AtomsConsumerImage
            v-if="minScreenMd"
            src="/images/site/components/order/pre-order-attention/head02_pc.svg"
          />
          <AtomsConsumerImage v-else src="/images/site/components/order/pre-order-attention/head02_sp.svg" />
          <p :class="$style.main_text">通常注文いただいた分はすぐにお届け！</p>
          <div :class="$style.sub_text">
            <p>※予約注文いただいた分は入荷次第の発送となります。</p>
          </div>
        </div>
      </div>
      <AtomsConsumerButton variant="primary" block @click="confirm">上記を確認しました</AtomsConsumerButton>
    </div>
  </MoleculesConsumerModal>
</template>

<script setup lang="ts">
type Props = {
  visible: boolean
}

const props = withDefaults(defineProps<Props>(), {
  visible: false,
})

const emit = defineEmits<{
  (e: "confirm"): void
  (e: "update:visible", visible: boolean): void
}>()

const localValue = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
})

const { width: windowWidth } = useWindowSize()
const minScreenMd = computed(() => windowWidth.value >= 768)

const confirm = (): void => {
  emit("update:visible", false)
  emit("confirm")
}
</script>

<style module scoped lang="scss">
.body {
  max-width: 516px;
  .head_text {
    text-align: center;
    font-size: $font-size-20;
    margin-top: 1.25rem;
    color: $primary;
    @include md {
      font-size: $font-size-24;
    }
    .br {
      @include md {
        display: none;
      }
    }
  }
  .attention {
    padding: 0 0.5rem;
    margin: 2rem 0;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    img {
      width: 100%;
    }
    .main_text {
      font-size: $font-size-16;
      color: $primary;
      font-weight: bold;
      margin-top: 1.25rem;
      @include md {
        font-size: $font-size-18;
        text-align: center;
      }
      &.red {
        color: $danger;
      }
      strong {
        text-decoration: underline;
      }
    }
    .sub_text {
      margin-top: 1rem;
      font-size: $font-size-12;
      color: $danger;
      @include md {
        font-size: $font-size-14;
      }
    }
    .section1 > .shipping_flow_image {
      margin-top: 1.25rem;
      @include md {
        margin-top: 1.5rem;
      }
    }
    .section2 > .sub_text {
      margin-top: 0.75rem;
    }
  }
}
</style>
